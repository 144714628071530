<!--购买门票-->
<template>
    <div class="app-container">
      <div style="width: 100%; height: 52px">
        <div style="height: 52px; float: right">
          <el-button-group style="margin-top: 10px; margin-right: 10px">
            <el-button :plain="rowspan !== true" icon="el-icon-s-unfold" size="small" type="primary"
                       @click="rowspan = true"></el-button>
            <el-button :plain="rowspan !== false" icon="el-icon-menu" size="small" type="primary"
                       @click="rowspan = false"></el-button>
          </el-button-group>
        </div>
      </div>
      <div style="width: 96%; min-height: 400px; padding: 0 2%">
        <el-row v-show="rowspan" v-loading="listloading">
          <el-col v-show="!goodsList.length" :span="24">
            <div style="text-align: center">
              <p>{{$t('No.Products.Yet')}}</p>
            </div>
          </el-col>
          <el-col v-for="item in goodsList" :key="item.id" :span="24">
            <div class="rowStyle hoverBlue" @click="goGoodsDetail(item)">
              <el-row>
                <el-col :span="10">
                  <div class="rowStyle_img" style="
                width: 250px;
                height: 250px;
                margin: 25px;
                float: left;
              ">
                    <el-image  fit="contain" :src="item.imgsrc" style="width: 250px;height: 250px">
                      <div slot="error" class="image-slot">
                        <i style="font-size: 250px" class="el-icon-picture-outline"></i>
                      </div>
                    </el-image>
                  </div>
                </el-col>
                <el-col :span="14">
                  <div style=" float: left; margin-top: 25px" class="text_h">
                    <p :title="$t(item.name)">{{ $t(item.name) }}</p>
<!--                    <p>{{ $t('pmProductList.price') + (':') }}<span :title="item.standardPrice >= 999999 ? '&#45;&#45;' : item.standardPrice">{{ item.standardPrice >= 999999 ? '&#45;&#45;' : item.standardPrice+' W$' }}</span></p>-->
                    <p>{{ $t('pmProductList.price') + (':') }}<span :title="item.standardPrice >= 999999 ? '--' : item.standardPrice">{{ item.standardPrice >= 999999 ? '--' : item.standardPrice }}</span></p>
                    <el-button type="success" @click.native.stop="getGoods(item)">{{ $t('common.buy') }}</el-button>
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-col>
        </el-row>
        <el-row v-show="!rowspan" v-loading="listloading" :gutter="20">
          <el-col v-show="!goodsList.length" :span="24">
            <div style="text-align: center">
              <p>{{$t('No.Products.Yet')}}</p>
            </div>
          </el-col>
          <el-col v-for="item in goodsList" :key="item.id" class="AppGoodsList" :lg="6"  :xs="12">
            <div class="colStyle hoverBlue" @click="goGoodsDetail(item)">
              <div class="colStyle_img" style=" width: 96%; height: 250px; margin: 2%; float: left;">
                <el-image  fit="contain" :src="item.imgsrc" style="width: 250px;height: 250px">
                  <div slot="error" class="image-slot">
                    <i style="font-size: 250px" class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div style="width: 96%; height: 350px; margin: 5px 2% 0; float: left" class="text_h">
                <p :title="$t(item.name)">{{ $t(item.name) }}</p>
<!--                <p>{{ $t('pmProductList.price') + (':') }}<span :title="item.standardPrice >= 999999 ? '&#45;&#45;' : item.standardPrice">{{ item.standardPrice >= 999999 ? '&#45;&#45;' : item.standardPrice+' W$' }}</span></p>-->
                <p>{{ $t('pmProductList.price') + (':') }}<span :title="item.standardPrice >= 999999 ? '--' : item.standardPrice">{{ item.standardPrice >= 999999 ? '--' : item.standardPrice }}</span></p>
                <el-button type="success" @click.native.stop="getGoods(item)">{{ $t('common.buy') }}</el-button>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import {companyTicketList} from "@/api/orderTicket/orderticket";

export default {
  name: "AdmissionTicket",
  data() {
    return {
      goodsList: [],
      listQuery: {
        page: 1,
        limit: 20,
      },
      listloading: false,
      total: 1,
      rowspan: false,
    };
  },
  computed: {
    ...mapGetters([
      "shoppingList",
      "language",
      "userCode",
      "ckCode",
      "goodsKindCode",
      "goodsKindName",
      "username",
      "isAgent",
      "permissions",
      "permission_routes",
      "companyCode"
    ]),
  },
  watch: {
    goodsKindCode(val) {
      if (val) {
        this.getList()
      }
    },
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listloading = true
      let ckCodeSend = this.ckCode ? this.ckCode : 'RUS'
      companyTicketList({
        ...this.listQuery,
        companyCode: ckCodeSend,
        kindcode: this.goodsKindCode,
      })
          .then((res) => {
            // for (let a = 0; a < res.data.length; a++) {
            //   if (res.data[a].imgsrc.indexOf(';') !== -1) {
            //     res.data[a].imgsrc = res.data[a].imgsrc.split(';')[0]
            //   } else {
            //     res.data[a].imgsrc = [res.data[a].imgsrc][0]
            //   }
            // }
            for(let i =0 ;i<res.data.length;i++){
              if(res.data[i].pictures.length>0){
                res.data[i].imgsrc = res.data[i].pictures[0].url
              }
            }
            this.goodsList = res.data
            this.listloading = false
          })
          .catch((err) => {
            console.warn(err);
            this.listloading = false
          });
    },
    goGoodsDetail(item) {
      item.menpiao = true
      this.$router.push({path: "/goodsdetail", query: {...item, isDoing: 'false'}})
    },
    getGoods(item) {
      this.$confirm(this.$t('Purchase.ticket').toString()+'?', '', {
      confirmButtonText: this.$t('Confirm.Order'),
      cancelButtonText: this.$t('operation.button.cancel'),
      customClass: 'msgBox'
    }).then(() => {
      console.log(item)
      item.qty = 1
      this.$router.push({path:'/member-register/purchase-ticket',query:{...item}})
      // this.$store.dispatch('shopping/addShoppings', JSON.parse(JSON.stringify(item)))
      this.$message({
        type: 'success',
        message: this.$t('bigoods.addcart.success').toString()
      })
    }).catch(() => {
      this.$message({
        type: 'info',
        message: this.$t('status.check.canceled') + ('!')
      })
    })
    }
  },
}
</script>

<style scoped>
.text_h p{
  height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rowStyle {
  height: 300px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.colStyle {
  height: 600px;
  overflow: hidden;
  margin: 10px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}

.hoverBlue:hover {
  /*border: 1px solid #333333 !important;*/
  -moz-box-shadow: 1px 3px 20px #8F8F8F;
  -webkit-box-shadow: 1px 3px 5px #8F8F8F;
  box-shadow: 1px 3px 20px #8F8F8F;
}
@media screen and (max-width: 991px){
  .el-image{
    width: 150px!important;
    height: 150px!important;
  }
  .colStyle_img{
    width: 100%!important;
    height: 150px!important;
  }
  .rowStyle_img{
    width: 100%!important;
    height: 100px!important;
    margin: 70px 5px!important;
  }
  .image-slot i{
    font-size: 100px!important;
  }
  .colStyle{
    height: 450px;
  }
}
</style>
